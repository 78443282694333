const counter = document.querySelector('.counter');

if (counter) {
  const counterBtnDec = counter.querySelector('.counter-btn--dec');
  const counterBtnInc = counter.querySelector('.counter-btn--inc');
  let ctrl = counter.querySelector('input[type="number"]');

  const node = document.querySelector('.calc__total-value span');
  const calcFormControl = document.querySelector('#calc-cost-field');
  const step = 2000;

  const fillPriceNode = (count) => {
    node.innerHTML = step * Number(count);
    if (calcFormControl) {
      calcFormControl.value = step * Number(count);
    }
  };

  counterBtnDec.addEventListener('click', () => {
    ctrl.value--;

    if (ctrl.value === '1') {
      !counterBtnDec.classList.contains('counter-btn--disabled')
        ? counterBtnDec.classList.add('counter-btn--disabled')
        : null;
    }

    fillPriceNode(ctrl.value);
  });

  counterBtnInc.addEventListener('click', () => {
    ctrl.value++;
    if (ctrl.value > '1') {
      counterBtnDec.classList.contains('counter-btn--disabled')
        ? counterBtnDec.classList.remove('counter-btn--disabled')
        : null;
    }
    fillPriceNode(ctrl.value);
  });
}
